@import '../tools';

.head {
  @apply absolute w-full py-20;
  &_nav {
    @apply translate-x-[-100%] lg:translate-x-[none] lg:flex z-2;

    @screen mmd {
      @apply flex flex-col opacity-0 absolute left-0 top-0 bg-[#5848D5] overflow-auto w-268 items-start z-1019;
      height: calc(100vh - 120px);
      box-shadow: 0px 6px 22px 0px rgba(7,13,63,0.3);
    }

    >li {
      >a {
        @apply text-white;
      }

      &:hover {
        >a {
          @apply hover:text-[#00a3ff];

          svg {
            path {
              fill: #00a3ff;
            }
          }
        }
      }

      ul.submenu {
        @apply lg:opacity-0 lg:pointer-events-none flex lg:justify-center
        overflow-hidden mmd:max-h-0
        absolute bg-white w-full left-0 rounded-b-10
        lg:px-120 lg:py-30 lg:top-90 gap-20 lg:gap-100;

        >li:has(img) {
          @apply my-auto hidden lg:block;

          img {
            @apply max-w-[250px] h-auto;
          }
        }
      }

      &.menu-item-has-children {

        @apply relative lg:static;

        &::after {
          @apply block w-15 h-10 lg:hidden absolute right-20 top-18 bg-cover bg-no-repeat;
          content: '';
          background-image: url('#{$svg}arrow-mobile.svg');
        }

        &.active {
          &::after {
            @apply rotate-180;
          }
        }

        p {
          @apply font-sb text-[#0D0C52];
        }

        >a {
          @apply relative lg:pb-30 lg:-mb-30 text-14;
  
          @screen lg {
            &::before {
              content: url('#{$svg}submenu-arrow.svg');
              @apply absolute top-31 opacity-0 w-32 left-0 right-0 mx-auto;
            }
          }
        }

        .sub-menu {
          @apply text-14 pl-18 flex flex-col gap-15;
          border-left: 2px solid #5848D54D;

          a {
            @apply text-[#0D0C52] hover:text-[#5848D5];
          }
        }

        @screen lg {
          &:hover {
            .submenu {
              @apply opacity-100 pointer-events-auto;
            }
            >a {
              @apply text-[#00a3ff];
  
              &::before {
                @apply opacity-100;
              }
            }
          }
        }
      }
    }
  }

  label {
    @apply absolute lg:hidden right-0 flex flex-col gap-3 w-22 h-15;
    span {
      @apply w-full h-3 bg-white;
    }
  }

  input:checked ~ ul {
    @apply translate-x-[0] opacity-100;

    >li {
      @apply w-full;

     >a {
        @apply px-20 py-10;
      }
    }

    .submenu {
      @apply relative flex-col;

      &.active {
        @apply max-h-[1000px] p-20;
      }
    }
  }
}